// gatsby-browser.js
import React from "react";
import { ApolloProvider } from "@apollo/client";
import client from "./src/API/apolloClient";

export const wrapRootElement = ({ element }) => {
  if (typeof window !== "undefined") {
    return <ApolloProvider client={client}>{element}</ApolloProvider>;
  }
  return element;
};
