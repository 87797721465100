exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aboutus-index-js": () => import("./../../../src/pages/aboutus/index.js" /* webpackChunkName: "component---src-pages-aboutus-index-js" */),
  "component---src-pages-cancellationpolicy-index-js": () => import("./../../../src/pages/cancellationpolicy/index.js" /* webpackChunkName: "component---src-pages-cancellationpolicy-index-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-events-index-js": () => import("./../../../src/pages/events/index.js" /* webpackChunkName: "component---src-pages-events-index-js" */),
  "component---src-pages-faq-index-js": () => import("./../../../src/pages/faq/index.js" /* webpackChunkName: "component---src-pages-faq-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-membership-form-index-js": () => import("./../../../src/pages/membershipForm/index.js" /* webpackChunkName: "component---src-pages-membership-form-index-js" */),
  "component---src-pages-membership-index-js": () => import("./../../../src/pages/membership/index.js" /* webpackChunkName: "component---src-pages-membership-index-js" */),
  "component---src-pages-privacypolicy-index-js": () => import("./../../../src/pages/privacypolicy/index.js" /* webpackChunkName: "component---src-pages-privacypolicy-index-js" */),
  "component---src-pages-terms-conditions-index-js": () => import("./../../../src/pages/terms&conditions/index.js" /* webpackChunkName: "component---src-pages-terms-conditions-index-js" */)
}

